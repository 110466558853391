import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WallModals from './WallModals';
import Toast from '../assets/Toast';

function RecommendedNetwork() {
    // Redux state
    const user = useSelector((state) => state.user.data);
    const isUserLogin = useSelector(state => state.user.isUserLogin);

    // Component state
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [selectedUserUid, setSelectedUserUid] = useState(null);
    const [toasts, setToasts] = useState([]);

    const openModal = (network, userUid) => {
        setSelectedNetwork(network);
        setSelectedUserUid(userUid);

        if (!isUserLogin) {

            showToast('error', 'To proceed with this action, you are required to log in.');
        } else {
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const showToast = (type, message, id) => {
        const newToast = {
            type,
            id,
            message,
        };
        setToasts([...toasts, newToast]);
    };

    const removeToast = (id) => {
        setToasts(toasts.filter((toast) => toast.id !== id));
    };

    return (
        <div className="recommended_network">
            <div className="network_list">
            <div className="recommended_card mmwall" onClick={() => openModal("mmwall", user?.user_uid)}>
                    <div className="recommended_bonus">
                        <span>+15%</span>
                    </div>
                    <div className="recommended_rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span></span>
                    </div>
                    <img className='network_img' src={process.env.PUBLIC_URL + "/assets/images/brand_mmwall.png"} alt='' />
                    <div className="textBox">
                        <div className='button_play'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.38462 0.879957C1.2447 0.792439 1.08603 0.746239 0.924463 0.745973C0.762898 0.745707 0.604099 0.791385 0.463936 0.878441C0.323774 0.965498 0.207158 1.09088 0.125745 1.24207C0.0443313 1.39325 0.000971806 1.56493 0 1.73996V14.3C0.000277175 14.4744 0.0426584 14.6457 0.122937 14.7969C0.203215 14.948 0.318598 15.0739 0.457631 15.1618C0.596665 15.2498 0.754513 15.2968 0.915506 15.2982C1.0765 15.2996 1.23504 15.2554 1.37538 15.17L11.5108 9.03996C11.6538 8.95452 11.7733 8.82952 11.8571 8.6777C11.941 8.52588 11.9861 8.35269 11.988 8.17578C11.9899 7.99888 11.9484 7.82461 11.8678 7.67074C11.7872 7.51688 11.6704 7.38893 11.5292 7.29996L1.38462 0.879957Z" fill="#01D676" />
                            </svg>
                        </div>
                        <div className='text button'>View Offer</div>
                    </div>
                    <div className='recommended_network_name'>MMWALL</div>
                </div>
                <div className="recommended_card torox" onClick={() => openModal("torox", user?.user_uid)}>
                    <div className="recommended_bonus">
                        <span>+25%</span>
                    </div>
                    <div className="recommended_rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span></span>
                    </div>
                    <img className='network_img' src={process.env.PUBLIC_URL + "/assets/images/brand_torox.png"} alt='' />
                    <div className="textBox">
                        <div className='button_play'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.38462 0.879957C1.2447 0.792439 1.08603 0.746239 0.924463 0.745973C0.762898 0.745707 0.604099 0.791385 0.463936 0.878441C0.323774 0.965498 0.207158 1.09088 0.125745 1.24207C0.0443313 1.39325 0.000971806 1.56493 0 1.73996V14.3C0.000277175 14.4744 0.0426584 14.6457 0.122937 14.7969C0.203215 14.948 0.318598 15.0739 0.457631 15.1618C0.596665 15.2498 0.754513 15.2968 0.915506 15.2982C1.0765 15.2996 1.23504 15.2554 1.37538 15.17L11.5108 9.03996C11.6538 8.95452 11.7733 8.82952 11.8571 8.6777C11.941 8.52588 11.9861 8.35269 11.988 8.17578C11.9899 7.99888 11.9484 7.82461 11.8678 7.67074C11.7872 7.51688 11.6704 7.38893 11.5292 7.29996L1.38462 0.879957Z" fill="#01D676" />
                            </svg>
                        </div>
                        <div className='text button'>View Offer</div>
                    </div>
                    <div className='recommended_network_name'>TOROX</div>
                </div>

                <div className="recommended_card adgatemedia" onClick={() => openModal("adgatemedia", user?.user_uid)}>
                <div className="recommended_bonus">
                        <span>+20%</span>
                    </div>
                    <div className="recommended_rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <span></span>
                    </div>
                    <img className='network_img' src={process.env.PUBLIC_URL + "/assets/images/brand_adgatemedia.png"} alt='' />
                    <div className="textBox">
                        <div className='button_play'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.38462 0.879957C1.2447 0.792439 1.08603 0.746239 0.924463 0.745973C0.762898 0.745707 0.604099 0.791385 0.463936 0.878441C0.323774 0.965498 0.207158 1.09088 0.125745 1.24207C0.0443313 1.39325 0.000971806 1.56493 0 1.73996V14.3C0.000277175 14.4744 0.0426584 14.6457 0.122937 14.7969C0.203215 14.948 0.318598 15.0739 0.457631 15.1618C0.596665 15.2498 0.754513 15.2968 0.915506 15.2982C1.0765 15.2996 1.23504 15.2554 1.37538 15.17L11.5108 9.03996C11.6538 8.95452 11.7733 8.82952 11.8571 8.6777C11.941 8.52588 11.9861 8.35269 11.988 8.17578C11.9899 7.99888 11.9484 7.82461 11.8678 7.67074C11.7872 7.51688 11.6704 7.38893 11.5292 7.29996L1.38462 0.879957Z" fill="#01D676" />
                            </svg>
                        </div>
                        <div className='text button'>View Offer</div>
                    </div>
                    <div className='recommended_network_name'>ADGATEMEDIA</div>
                </div>

                <div className="recommended_card hangmyads" onClick={() => openModal("hangmyads", user?.user_uid)}>
                    <div className="recommended_bonus">
                        <span>+30%</span>
                    </div>
                    <div className="recommended_rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <span></span>
                    </div>
                    <img className='network_img' src={process.env.PUBLIC_URL + "/assets/images/brand_hangmyads.png"} alt='' />
                    <div className="textBox">
                        <div className='button_play'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.38462 0.879957C1.2447 0.792439 1.08603 0.746239 0.924463 0.745973C0.762898 0.745707 0.604099 0.791385 0.463936 0.878441C0.323774 0.965498 0.207158 1.09088 0.125745 1.24207C0.0443313 1.39325 0.000971806 1.56493 0 1.73996V14.3C0.000277175 14.4744 0.0426584 14.6457 0.122937 14.7969C0.203215 14.948 0.318598 15.0739 0.457631 15.1618C0.596665 15.2498 0.754513 15.2968 0.915506 15.2982C1.0765 15.2996 1.23504 15.2554 1.37538 15.17L11.5108 9.03996C11.6538 8.95452 11.7733 8.82952 11.8571 8.6777C11.941 8.52588 11.9861 8.35269 11.988 8.17578C11.9899 7.99888 11.9484 7.82461 11.8678 7.67074C11.7872 7.51688 11.6704 7.38893 11.5292 7.29996L1.38462 0.879957Z" fill="#01D676" />
                            </svg>
                        </div>
                        <div className='text button'>View Offer</div>
                    </div>
                    <div className='recommended_network_name'>HANGMYADS</div>
                </div>
                

                <div className="recommended_card lootably" onClick={() => openModal("lootably", user?.user_uid)}>
                    <div className="recommended_bonus">
                        <span>+20%</span>
                    </div>
                    <div className="recommended_rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span></span>
                    </div>
                    <img className='network_img' src={process.env.PUBLIC_URL + "/assets/images/brand_lootably.png"} alt='' />
                    <div className="textBox">
                        <div className='button_play'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.38462 0.879957C1.2447 0.792439 1.08603 0.746239 0.924463 0.745973C0.762898 0.745707 0.604099 0.791385 0.463936 0.878441C0.323774 0.965498 0.207158 1.09088 0.125745 1.24207C0.0443313 1.39325 0.000971806 1.56493 0 1.73996V14.3C0.000277175 14.4744 0.0426584 14.6457 0.122937 14.7969C0.203215 14.948 0.318598 15.0739 0.457631 15.1618C0.596665 15.2498 0.754513 15.2968 0.915506 15.2982C1.0765 15.2996 1.23504 15.2554 1.37538 15.17L11.5108 9.03996C11.6538 8.95452 11.7733 8.82952 11.8571 8.6777C11.941 8.52588 11.9861 8.35269 11.988 8.17578C11.9899 7.99888 11.9484 7.82461 11.8678 7.67074C11.7872 7.51688 11.6704 7.38893 11.5292 7.29996L1.38462 0.879957Z" fill="#01D676" />
                            </svg>
                        </div>
                        <div className='text button'>View Offer</div>
                    </div>
                    <div className='recommended_network_name'>LOOTABLY</div>
                </div>

                

                

                <div className="recommended_card notik" onClick={() => openModal("notik", user?.user_uid)}>
                    <div className="recommended_bonus">
                        <span>+25%</span>
                    </div>
                    <div className="recommended_rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span></span>
                    </div>
                    <img className='network_img' src={process.env.PUBLIC_URL + "/assets/images/brand_notik.png"} alt='' />
                    <div className="textBox">
                        <div className='button_play'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.38462 0.879957C1.2447 0.792439 1.08603 0.746239 0.924463 0.745973C0.762898 0.745707 0.604099 0.791385 0.463936 0.878441C0.323774 0.965498 0.207158 1.09088 0.125745 1.24207C0.0443313 1.39325 0.000971806 1.56493 0 1.73996V14.3C0.000277175 14.4744 0.0426584 14.6457 0.122937 14.7969C0.203215 14.948 0.318598 15.0739 0.457631 15.1618C0.596665 15.2498 0.754513 15.2968 0.915506 15.2982C1.0765 15.2996 1.23504 15.2554 1.37538 15.17L11.5108 9.03996C11.6538 8.95452 11.7733 8.82952 11.8571 8.6777C11.941 8.52588 11.9861 8.35269 11.988 8.17578C11.9899 7.99888 11.9484 7.82461 11.8678 7.67074C11.7872 7.51688 11.6704 7.38893 11.5292 7.29996L1.38462 0.879957Z" fill="#01D676" />
                            </svg>
                        </div>
                        <div className='text button'>View Offer</div>
                    </div>
                    <div className='recommended_network_name'>NOTIK</div>
                </div>



                {/* <div className="recommended_card revenue" onClick={() => openModal("revenue", user?.user_uid)}>
                    <div className="recommended_soon">
                        <span>Comming Soon</span>
                    </div>
                    <div className="recommended_rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span></span>
                    </div>
                    <img className='network_img' src={process.env.PUBLIC_URL + "/assets/images/brand_revu.png"} alt='' />
                    <div className="textBox">
                        <div className='button_play'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.38462 0.879957C1.2447 0.792439 1.08603 0.746239 0.924463 0.745973C0.762898 0.745707 0.604099 0.791385 0.463936 0.878441C0.323774 0.965498 0.207158 1.09088 0.125745 1.24207C0.0443313 1.39325 0.000971806 1.56493 0 1.73996V14.3C0.000277175 14.4744 0.0426584 14.6457 0.122937 14.7969C0.203215 14.948 0.318598 15.0739 0.457631 15.1618C0.596665 15.2498 0.754513 15.2968 0.915506 15.2982C1.0765 15.2996 1.23504 15.2554 1.37538 15.17L11.5108 9.03996C11.6538 8.95452 11.7733 8.82952 11.8571 8.6777C11.941 8.52588 11.9861 8.35269 11.988 8.17578C11.9899 7.99888 11.9484 7.82461 11.8678 7.67074C11.7872 7.51688 11.6704 7.38893 11.5292 7.29996L1.38462 0.879957Z" fill="#01D676" />
                            </svg>
                        </div>
                        <div className='text button'>View Offer</div>
                    </div>
                    <div className='recommended_network_name'>REVENUE</div>
                </div> */}

                <div className="recommended_card bitlabs" >
                    <div className="recommended_soon">
                        <span>Comming Soon</span>
                    </div>
                    <div className="recommended_rating">
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_rating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <img src={process.env.PUBLIC_URL + "/assets/images/network_unrating.svg"} alt='' />
                        <span></span>
                    </div>
                    <img className='network_img' src={process.env.PUBLIC_URL + "/assets/images/brand_bitlabss.png"} alt='' />
                    <div className="textBox">
                        <div className='button_play'>
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.38462 0.879957C1.2447 0.792439 1.08603 0.746239 0.924463 0.745973C0.762898 0.745707 0.604099 0.791385 0.463936 0.878441C0.323774 0.965498 0.207158 1.09088 0.125745 1.24207C0.0443313 1.39325 0.000971806 1.56493 0 1.73996V14.3C0.000277175 14.4744 0.0426584 14.6457 0.122937 14.7969C0.203215 14.948 0.318598 15.0739 0.457631 15.1618C0.596665 15.2498 0.754513 15.2968 0.915506 15.2982C1.0765 15.2996 1.23504 15.2554 1.37538 15.17L11.5108 9.03996C11.6538 8.95452 11.7733 8.82952 11.8571 8.6777C11.941 8.52588 11.9861 8.35269 11.988 8.17578C11.9899 7.99888 11.9484 7.82461 11.8678 7.67074C11.7872 7.51688 11.6704 7.38893 11.5292 7.29996L1.38462 0.879957Z" fill="#01D676" />
                            </svg>
                        </div>
                        <div className='text button'>View Offer</div>
                    </div>
                    <div className='recommended_network_name'>BITLABS</div>
                </div>












            </div>

            <div className="notifications">
                {toasts.map((toast, index) => (
                    <Toast
                        key={index}
                        id={toast.id}
                        message={toast.message}
                        type={toast.type}
                        onClose={removeToast}
                    />
                ))}
            </div>

            <WallModals isOpen={isModalOpen} onClose={closeModal} selectedNetwork={selectedNetwork} selectedUserUid={selectedUserUid} />
        </div>
    )
}

export default RecommendedNetwork;
